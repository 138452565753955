import React, { useEffect, useMemo, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { imgUrl, siteUrl } from '../../config/config';
import { getPost } from '../../store/ducks/post/actions';
import postSelector from '../../store/ducks/post/selectors/postSelector';
import moment from 'moment';
import './Post.scss';
import {
    Video,
    Image,
    Text,
    CatchyQuote,
    List,
    Html,
    Table,
    Header,
} from '../../components/Post/components';
import { ScrollUp, Share } from '../../components';
import { PageMeta } from 'components/PageMeta/PageMeta';
import { State } from 'types/redux';
import Page404 from '../../pages/404/404';
type Props = {
    match?: {
        params?: {
            id: string;
        };
    };
};

import { LangContext } from "../../components/App/App";

const Post = (props: Props) => {
    const dispatch = useDispatch();

    const post = useSelector(postSelector);
    const status = useSelector((state: State) => state.postReducer.status);
    const lang = useContext(LangContext);

    useEffect(() => {
        if (lang && lang.lang && lang.lang.value && props.match.params.id) {
            const id: string = props.match.params.id;
            dispatch(getPost({ slug: id, lang: lang.lang.value }));
        }
    }, [props.match.params.id, lang]);

    if (status === 'FAILURE') {
        return <Page404 />;
    }

    return (
        <>
            <PageMeta title={post && post.title} />
            {status && status === "SUCCESS" &&
                <article className="article">
                    <div className="container">
                        {post && (
                            <div className="article-wrapper">
                                <h1 className="post-title">{post && post.title}</h1>
                                <div className="article-top">
                                    <div className="article-date">
                                        {(post && lang) &&
                                            moment(post.date)
                                                .locale(lang.lang.locale)
                                                .format('LL')}
                                    </div>
                                    <div className="article-share">
                                        <Share
                                            caption={post.shareCaption}
                                        />
                                    </div>
                                </div>
                                <div className="article-img">
                                    {post && (
                                        <img src={siteUrl + post.image} alt="" />
                                    )}
                                    {/*<div className="article-img__subscribe">
                                 <strong>Credit:</strong>
                                 <span>Getty Images</span>
                                 </div>*/}
                                </div>
                                <div className="article-content__wrapper">
                                    <div className="product-list__about">
                                        {post &&
                                            (post.reference.title ||
                                                post.reference.caption) &&
                                            post.reference.url && (
                                                <>
                                                    {post.reference.title && (
                                                        <div className="product-list__about-title">
                                                            {post.reference.title}
                                                        </div>
                                                    )}
                                                    {post.reference.caption && (
                                                        <div className="product-list__about-descr">
                                                            {post.reference.caption}
                                                        </div>
                                                    )}
                                                    {post.reference.url && (
                                                        <>
                                                            {post.reference.type === "inner"
                                                                &&
                                                                <Link
                                                                    to={post.reference.url}
                                                                    className="product-list__about-link"
                                                                >
                                                                    <img src={imgUrl + "product/arrow-right-circle-fill.svg"} alt="" />
                                                                </Link>
                                                                ||
                                                                <a href={post.reference.url} target="_blank">
                                                                    <img src={imgUrl + "product/arrow-right-circle-fill.svg"} alt="" />
                                                                </a>
                                                            }
                                                        </>
                                                    )}
                                                </>
                                            )}
                                    </div>
                                    <div className="article-content">
                                        {post &&
                                            post.content.length > 0 &&
                                            post.content.map((i, index) => (
                                                <>
                                                    <Text item={i} />
                                                    <List item={i} />
                                                    <Image item={i} />
                                                    <CatchyQuote item={i} />
                                                    <Video item={i} />
                                                    <Html item={i} />
                                                    <Table item={i} />
                                                    <Header item={i} />
                                                </>
                                            ))}
                                        <div className="article-bottom">
                                            <div className="article-share">
                                                <Share
                                                    caption={post.shareCaption}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <ScrollUp />
                </article>
            }
        </>
    );
};

export default Post;
