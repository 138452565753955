import { createSelector } from "reselect";
import { tPostData } from "../types";
import moment from "moment";

const getState = (state) => state.postReducer.post;

const postSelector = createSelector([getState], (item) => {
    moment.locale("ru");
    let results:tPostData;

    if (item) {
        const { attributes } = item;
        let allDivs:any[] = [];

        if (attributes && attributes.PostBody && attributes.PostBody.length > 0) {
            attributes.PostBody.forEach((div) => {
                const type = div.__component.split(".")[1];

                switch (type) {
                    case "text":
                        allDivs = [
                            ...allDivs,
                            {
                                type,
                                text: div.Text,
                            }
                        ];
                        //const textJson:any = JSON.parse(div.Text);
                        /*if (textJson) {
                            const ps:any[] = textJson.blocks;
                            if (ps && ps.length > 0) {
                                ps.forEach((p, index) => {
                                    switch (p.type) {
                                        case "paragraph":
                                            allDivs = [
                                                ...allDivs,
                                                {
                                                    lead: (type === "lead-text" && true) || false,
                                                    type: p.type,
                                                    text: p.data && p.data.text,
                                                },
                                            ];
                                            break;
                                        case "list":
                                            allDivs = [
                                                ...allDivs,
                                                {
                                                    type: p.type,
                                                    list: p.data && p.data.items,
                                                    listStyle: p.data.style,
                                                },
                                            ];
                                            break;
                                        case "quote":
                                            let name = "";
                                            if (p.data && p.data.caption) {
                                                name = p.data.caption;
                                                if (name.indexOf("<br>") > -1) {
                                                    let startSubstr = name.indexOf("<br>");
                                                    name = name.substring(0, startSubstr);
                                                }
                                                if (name.indexOf("<br/>") > -1) {
                                                    let startSubstr = name.indexOf("<br/>");
                                                    name = name.substring(0, startSubstr);
                                                }
                                            }
                                            allDivs = [
                                                ...allDivs,
                                                {
                                                    type: p.type,
                                                    text: p.data && p.data.text,
                                                    name,
                                                },
                                            ];
                                            break;
                                        case "raw":
                                            allDivs = [
                                                ...allDivs,
                                                {
                                                    type: p.type,
                                                    text: p.data && p.data.html,
                                                },
                                            ];
                                            break;
                                        case "table":
                                            allDivs = [
                                                ...allDivs,
                                                {
                                                    type: p.type,
                                                    content: p.data && p.data.content,
                                                },
                                            ];
                                            break;
                                        case "header":
                                            allDivs = [
                                                ...allDivs,
                                                {
                                                    type: p.type,
                                                    text: p.data && p.data.text,
                                                    level: p.data && p.data.level,
                                                },
                                            ];
                                            break;
                                        case "image":
                                            let caption:string;
                                            if (p.data && p.data.caption) {
                                                caption = p.data.caption;
                                                if (caption.indexOf("<br>") > -1) {
                                                    let startSubstr = caption.indexOf("<br>");
                                                    caption = caption.substring(0, startSubstr);
                                                }
                                                if (caption.indexOf("<br/>") > -1) {
                                                    let startSubstr = caption.indexOf("<br/>");
                                                    caption = caption.substring(0, startSubstr);
                                                }
                                            }
                                            allDivs = [
                                                ...allDivs,
                                                {
                                                    type: p.type,
                                                    image: (p.data && p.data.file) && p.data.file.url,
                                                    caption,
                                                },
                                            ];
                                            break;
                                    }
                                });
                            }
                        }*/
                        break;

                    case "quote":
                        const quoteJson = div.Text;
                        if (quoteJson) {
                            allDivs = [
                                ...allDivs,
                                {
                                    type,
                                    text: quoteJson,
                                    image:
                                        div.AuthorPhoto &&
                                        div.AuthorPhoto.data &&
                                        div.AuthorPhoto.data.attributes.url,
                                    name: div.AuthorName,
                                    post: div.AuthorPost,
                                },
                            ];
                        }
                        break;

                    case "video":
                        allDivs = [
                            ...allDivs,
                            {
                                type,
                                caption: div.Caption,
                                video: div.VideoCode,
                            },
                        ];
                        break;

                }
            });
        }
        results = {
            id: item.id,
            title: attributes.Title,
            image:
                attributes.Image &&
                attributes.Image.data &&
                attributes.Image.data.attributes &&
                attributes.Image.data.attributes.url &&
                attributes.Image.data.attributes.url,
            date: moment(attributes.Date),
            //postBody: attributes.PostBody,
            content: allDivs,
            reference: {
                title: attributes.Reference && attributes.Reference.Title,
                caption: attributes.Reference && attributes.Reference.Caption,
                url: attributes.Reference && attributes.Reference.Url,
                type: (attributes && attributes.Reference && attributes.Reference.Url && (attributes.Reference.Url.indexOf("https://") > -1 || attributes.Reference.Url.indexOf("http://") > -1)) && "outer" || "inner",
            },
            shareCaption: attributes.ShareCaption,
        }
    }

    return results;
});

export default postSelector;
