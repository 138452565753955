import React, { useEffect } from 'react';
import { paragraphType } from '../../constants';
import { useRemarkSync } from 'react-remark';
import './text.scss';

type tItem = {
    type: string;
    text: string;
    lead: string;
};

export const Text = props => {
    const item: tItem = props.item;
    const textContent = useRemarkSync(item.text);

    return (
        <>
            {item.type === paragraphType && (
                <>{textContent}</>
            )}
        </>
    );
};

export default Text;
